import { useMediaQuery } from "@mantine/hooks";

export const useColorMode = () => {
    const isDeviceColorModeDark = useMediaQuery("(prefers-color-scheme: dark)");
    const colorMode = isDeviceColorModeDark ? "dark" : "light";

    return {
        colorMode,
        isDark: colorMode === "dark",
    };
};
