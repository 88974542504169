import { MultiSelect, MultiSelectProps } from "@mantine/core";
import { Controller, FieldPath, FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

type FormMultiSelectProps<T extends FieldValues> = {
    name: FieldPath<T>;
} & Omit<MultiSelectProps, "onChange" | "value" | "id">;

export const FormMultiSelect = <T extends FieldValues>({
    name,
    data,
    disabled = false,
    clearable = true,
    searchable = true,
    ...rest
}: FormMultiSelectProps<T>) => {
    const { setValue, control } = useFormContext<T>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ fieldState: { error }, field: { value } }) => {
                return (
                    <MultiSelect
                        {...rest}
                        onChange={(e) => {
                            setValue(name, e as PathValue<T, Path<T>>, { shouldDirty: true });
                        }}
                        value={value}
                        data={data}
                        error={error?.message?.toString()}
                        clearable={clearable}
                        disabled={disabled}
                        searchable={searchable}
                    />
                );
            }}
        />
    );
};
