import { Button, Title } from "@mantine/core";
import { SidebarItemProps } from "shared/components/global/Sidebar/SidebarItem";
import classes from "shared/components/global/Sidebar/SidebarMobileItem.module.css";

type SidebarMobileItemProps = SidebarItemProps;

export const SidebarMobileItem = ({ icon: Icon, label, isActive }: SidebarMobileItemProps) => {
    return (
        <Button leftSection={<Icon size={20} stroke={1.5} />} variant="subtle" className={classes.item} data-active={isActive || undefined}>
            <Title order={4}>{label}</Title>
        </Button>
    );
};
