import { Box } from "@mantine/core";
import { Link, LinkProps } from "@tanstack/react-router";
import { ReactNode } from "react";
import classes from "shared/components/global/Breadcrumb/Breadcrumb.module.css";

interface BreadcrumbProps extends Omit<LinkProps, "className"> {
    children: ReactNode;
}

export const Breadcrumb = ({ to, children, activeOptions, ...rest }: BreadcrumbProps) => {
    return (
        <Link {...rest} to={to} className={classes.link} activeOptions={{ ...activeOptions, exact: true }}>
            {({ isActive }) => {
                return (
                    <Box className={classes.text} component="span" data-active={isActive || undefined}>
                        {children}
                    </Box>
                );
            }}
        </Link>
    );
};
