import { Stack, Text, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";
import { useAuthStore } from "shared/stores/authStore";

const ProfileComponent = () => {
    const user = useAuthStore((s) => s.user);

    return (
        <Stack>
            <Title order={2}>Profile</Title>
            <Text>{user?.username}</Text>
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/profile/")({
    component: ProfileComponent,
});
