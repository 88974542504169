import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { RichTextContent } from "shared/components/form/RichTextContent";
import classes from "shared/components/messages/MessageGridItem.module.css";
import { MessageStatusBadge } from "shared/components/messages/MessageStatusBadge";
import { useColorMode } from "shared/hooks/useColorMode";
import { MessageSeriesDetailViewQuery, MessageStatus } from "src/gql/graphql";
import { match, P } from "ts-pattern";

export const MessageGridItem = ({
    message,
    seriesId,
}: {
    message: MessageSeriesDetailViewQuery["messageSeries"]["messages"][number];
    seriesId: string;
}) => {
    const { isDark } = useColorMode();

    const time = match(message)
        .with({ status: MessageStatus.Scheduled, scheduledFor: P.number }, (message) => message.scheduledFor)
        .with({ status: MessageStatus.Published, publishedAt: P.number }, (message) => message.publishedAt)
        .otherwise(() => null);

    return (
        <Link
            as="span"
            to={
                [MessageStatus.Published, MessageStatus.Scheduled].includes(message.status)
                    ? "/series/$seriesId/message/$messageId"
                    : "/series/$seriesId/message/$messageId/edit"
            }
            params={{ seriesId: seriesId, messageId: message.id }}
            className={classes.link}
        >
            <Paper shadow="xs" p="md" withBorder={isDark} className={classes.paper}>
                <Stack>
                    <Group justify="space-between">
                        <Group>
                            <Title order={5}>{message.subject}</Title>
                            <MessageStatusBadge status={message.status} />
                        </Group>
                        {time && <Text>{dayjs(new Date(time)).format("LT L")}</Text>}
                    </Group>
                    {match({ body: message.body, smsBody: message.smsBodyOverride, emailBody: message.emailBodyOverride })
                        .with({ smsBody: P.string }, ({ smsBody }) => <RichTextContent content={smsBody} />)
                        .with({ emailBody: P.string }, ({ emailBody }) => <RichTextContent content={emailBody} />)
                        .otherwise(() => (
                            <RichTextContent content={message.body} />
                        ))}
                </Stack>
            </Paper>
        </Link>
    );
};
