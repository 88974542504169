import { Link } from "@tanstack/react-router";
import { SidebarItem, SidebarItemProps } from "shared/components/global/Sidebar/SidebarItem";
import { SidebarMobileItem } from "shared/components/global/Sidebar/SidebarMobileItem";

type SidebarLinkProps = {
    to: string;
    mobile?: boolean;
    onClick?: () => void;
} & SidebarItemProps;

export const SidebarLink = ({ icon, label, to, mobile, onClick }: SidebarLinkProps) => {
    const SidebarItemComponent = mobile ? SidebarMobileItem : SidebarItem;

    return (
        <Link to={to} onClick={onClick}>
            {({ isActive }) => {
                return <SidebarItemComponent isActive={isActive} icon={icon} label={label} />;
            }}
        </Link>
    );
};
