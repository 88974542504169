import { zodResolver } from "@hookform/resolvers/zod";
import { Anchor, Button, Checkbox, Container, Group, Paper, Text, Title } from "@mantine/core";
import { createFileRoute, redirect, useNavigate, useRouter, useRouterState, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useLogin } from "shared/api/authApi";
import { AppForm } from "shared/components/form/AppForm";
import { FormTextInput } from "shared/components/form/FormTextInput";
import { useAuthStore } from "shared/stores/authStore";
import { z } from "zod";

const fallback = "/series" as const;

const LoginComponent = () => {
    const router = useRouter();
    const isLoading = useRouterState({ select: (s) => s.isLoading });
    const navigate = useNavigate();
    const search = useSearch({ from: "/login" });
    const { setUser, setSession } = useAuthStore();

    const form = useForm({
        mode: "onSubmit",
        resolver: zodResolver(
            z.object({
                username: z.string().trim().min(1),
            }),
        ),
        defaultValues: {
            username: "",
        },
    });

    const { login, isPending } = useLogin({
        onSuccess: async (data) => {
            setUser({ username: data.username });
            setSession({ apiKey: "DUMMY" });

            await router.invalidate();
            await navigate({ to: search.redirect || fallback });
        },
    });

    const isLoggingIn = isLoading || form.formState.isSubmitting || isPending;

    return (
        <Container size={420} my={40}>
            <Title ta="center">Welcome back!</Title>
            <Text c="dimmed" size="sm" ta="center" mt={5}>
                Do not have an account yet?{" "}
                <Anchor size="sm" component="button">
                    Create account
                </Anchor>
            </Text>
            <Paper withBorder shadow="md" p="lg" mt="lg" radius="md">
                <AppForm<{ username: string }> form={form} onSubmit={(data) => login(data)}>
                    <FormTextInput label="Username" name="username" disabled={isLoggingIn} />
                    <Group justify="space-between" mt="lg">
                        <Checkbox label="Remember me" />
                        <Anchor component="button" type="button" size="sm">
                            Forgot password?
                        </Anchor>
                    </Group>
                    <Button type="submit" fullWidth mt="xl" disabled={isLoggingIn}>
                        {isLoggingIn ? "Loading..." : "Login"}
                    </Button>
                </AppForm>
            </Paper>
        </Container>
    );
};

/** @public */
export const Route = createFileRoute("/login")({
    validateSearch: z.object({
        redirect: z.string().optional().catch(""),
    }),
    beforeLoad: ({ context, search }) => {
        if (context.auth.session) {
            throw redirect({ to: search.redirect || fallback });
        }
    },
    component: LoginComponent,
});
