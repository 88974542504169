import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AppError } from "shared/components/global/AppError/AppError";
import { NotFound } from "shared/components/global/NotFound/NotFound";
import { AuthStore } from "shared/stores/authStore";

interface AuthRouterContext {
    auth: AuthStore;
}

/** @public */
export const Route = createRootRouteWithContext<AuthRouterContext>()({
    errorComponent: AppError,
    notFoundComponent: NotFound,
    component: () => (
        <>
            <Outlet />
            {import.meta.env.VITE_ENVIRONMENT !== "prod" && <TanStackRouterDevtools position="bottom-left" initialIsOpen={false} />}
        </>
    ),
});
