import { Badge } from "@mantine/core";
import { MessageSeriesNode, MessageSeriesStatus } from "src/gql/graphql";
import { match } from "ts-pattern";

export const MessageSeriesStatusBadge = ({ status }: Pick<MessageSeriesNode, "status">) => {
    return match(status)
        .with(MessageSeriesStatus.Open, (status) => <Badge color="green">{status}</Badge>)
        .with(MessageSeriesStatus.Closed, (status) => <Badge color="red">{status}</Badge>)
        .exhaustive();
};
