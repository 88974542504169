import { z } from 'zod'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GeoPoint: { input: string; output: string; }
  Long: { input: number; output: number; }
  UUID: { input: string; output: string; }
};

export type CreateMessageInfoInput = {
  body: Scalars['String']['input'];
  emailBodyOverride?: InputMaybe<Scalars['String']['input']>;
  recipientGroups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  recipientUsers?: InputMaybe<Array<Scalars['UUID']['input']>>;
  severity?: InputMaybe<MessageSeverity>;
  smsBodyOverride?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateMessageSeriesInfoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['Long']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MessageNode = {
  __typename?: 'MessageNode';
  body: Scalars['String']['output'];
  emailBodyOverride?: Maybe<Scalars['String']['output']>;
  groupRecipients: Array<UserGroupNode>;
  id: Scalars['UUID']['output'];
  publishedAt?: Maybe<Scalars['Long']['output']>;
  recipientCount: Scalars['Long']['output'];
  scheduledFor?: Maybe<Scalars['Long']['output']>;
  series: MessageSeriesNode;
  severity: MessageSeverity;
  smsBodyOverride?: Maybe<Scalars['String']['output']>;
  status: MessageStatus;
  subject: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  userRecipients: Array<UserNode>;
};

export type MessageSeriesMessagesFilterInput = {
  severity?: InputMaybe<MessageSeverity>;
  status?: InputMaybe<MessageStatus>;
};

export type MessageSeriesNode = {
  __typename?: 'MessageSeriesNode';
  description?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<Scalars['Long']['output']>;
  id: Scalars['String']['output'];
  messageCount: Scalars['Int']['output'];
  messages: Array<MessageNode>;
  status: MessageSeriesStatus;
  title?: Maybe<Scalars['String']['output']>;
  type: MessageSeriesTypeNode;
};


export type MessageSeriesNodeMessagesArgs = {
  filter?: InputMaybe<MessageSeriesMessagesFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum MessageSeriesStatus {
  Closed = 'Closed',
  Open = 'Open'
}

export enum MessageSeriesTypeIcon {
  Icon1 = 'Icon1',
  Icon2 = 'Icon2',
  Icon3 = 'Icon3',
  Icon4 = 'Icon4'
}

export type MessageSeriesTypeNode = {
  __typename?: 'MessageSeriesTypeNode';
  icon: MessageSeriesTypeIcon;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum MessageSeverity {
  Critical = 'Critical',
  Major = 'Major',
  Minor = 'Minor'
}

export enum MessageStatus {
  Draft = 'Draft',
  Published = 'Published',
  Scheduled = 'Scheduled'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   *
   *         Creates a new Draft message independent of an existing Series. A new series will be created alongside the message,
   *         and the new message will be associated with thew new message series.
   *
   */
  createMessage: MessageNode;
  /**
   *
   *         Creates a new Draft message associated with an existing series.
   *
   */
  createMessageOnSeries: MessageNode;
  deleteMessageDraft: Scalars['Boolean']['output'];
  /**
   *
   *         Publishes a given Draft message by routing it through the CMApi. The message's status will be set to Published. This method will fail if the status of the
   *         message is not 'Draft'
   *
   */
  publishMessage: MessageNode;
  /**
   *
   *         Schedules a given Draft message to be published later at the given unix epochMs timestamp. The message' status will be set to Scheduled until the message is published.
   *         This method will fail if the status is not "Draft'.
   *
   */
  scheduleMessage: MessageNode;
  updateMessage: MessageNode;
};


export type MutationCreateMessageArgs = {
  params: CreateMessageInfoInput;
  seriesParams?: InputMaybe<CreateMessageSeriesInfoInput>;
};


export type MutationCreateMessageOnSeriesArgs = {
  params: CreateMessageInfoInput;
  seriesId: Scalars['UUID']['input'];
};


export type MutationDeleteMessageDraftArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPublishMessageArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationScheduleMessageArgs = {
  epochMs: Scalars['Long']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUpdateMessageArgs = {
  id: Scalars['UUID']['input'];
  params: UpdateMessageInfoInput;
  seriesParams?: InputMaybe<UpdateMessageSeriesInfoInput>;
};

export type Query = {
  __typename?: 'Query';
  message: MessageNode;
  messageSeries: MessageSeriesNode;
  messages: Array<MessageNode>;
  timeline: TimelineNode;
  user: UserNode;
  userGroup: UserGroupNode;
  userGroups: Array<UserGroupNode>;
  users: Array<UserNode>;
};


export type QueryMessageArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMessageSeriesArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMessagesArgs = {
  status?: InputMaybe<MessageStatus>;
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserGroupArgs = {
  id: Scalars['UUID']['input'];
};

export type TimelineNode = {
  __typename?: 'TimelineNode';
  series: Array<MessageSeriesNode>;
};

export type UpdateMessageInfoInput = {
  body: Scalars['String']['input'];
  emailBodyOverride?: InputMaybe<Scalars['String']['input']>;
  recipientGroups?: InputMaybe<Array<Scalars['UUID']['input']>>;
  recipientUsers?: InputMaybe<Array<Scalars['UUID']['input']>>;
  severity?: InputMaybe<MessageSeverity>;
  smsBodyOverride?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMessageSeriesInfoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['Long']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroupNode = {
  __typename?: 'UserGroupNode';
  expanded: Array<UserNode>;
  groups: Array<UserGroupNode>;
  id: Scalars['String']['output'];
  memberCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  users: Array<UserNode>;
};

export type UserNode = {
  __typename?: 'UserNode';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['GeoPoint']['output']>;
};


type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const MessageSeriesStatusSchema = z.nativeEnum(MessageSeriesStatus);

export const MessageSeriesTypeIconSchema = z.nativeEnum(MessageSeriesTypeIcon);

export const MessageSeveritySchema = z.nativeEnum(MessageSeverity);

export const MessageStatusSchema = z.nativeEnum(MessageStatus);

export function CreateMessageInfoInputSchema(): z.ZodObject<Properties<CreateMessageInfoInput>> {
  return z.object({
    body: z.string(),
    emailBodyOverride: z.string().nullish(),
    recipientGroups: z.array(z.string()).nullish(),
    recipientUsers: z.array(z.string()).nullish(),
    severity: MessageSeveritySchema.nullish(),
    smsBodyOverride: z.string().nullish(),
    subject: z.string(),
    tags: z.array(z.string()).nullish()
  })
}

export function CreateMessageSeriesInfoInputSchema(): z.ZodObject<Properties<CreateMessageSeriesInfoInput>> {
  return z.object({
    description: z.string().nullish(),
    expiry: z.number().nullish(),
    title: z.string().nullish()
  })
}

export function MessageSeriesMessagesFilterInputSchema(): z.ZodObject<Properties<MessageSeriesMessagesFilterInput>> {
  return z.object({
    severity: MessageSeveritySchema.nullish(),
    status: MessageStatusSchema.nullish()
  })
}

export function UpdateMessageInfoInputSchema(): z.ZodObject<Properties<UpdateMessageInfoInput>> {
  return z.object({
    body: z.string(),
    emailBodyOverride: z.string().nullish(),
    recipientGroups: z.array(z.string()).nullish(),
    recipientUsers: z.array(z.string()).nullish(),
    severity: MessageSeveritySchema.nullish(),
    smsBodyOverride: z.string().nullish(),
    subject: z.string(),
    tags: z.array(z.string()).nullish()
  })
}

export function UpdateMessageSeriesInfoInputSchema(): z.ZodObject<Properties<UpdateMessageSeriesInfoInput>> {
  return z.object({
    description: z.string().nullish(),
    expiry: z.number().nullish(),
    title: z.string().nullish()
  })
}
