import { Textarea, TextareaProps } from "@mantine/core";
import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

type FormTextareaProps<T extends FieldValues> = {
    name: FieldPath<T>;
} & TextareaProps;

export const FormTextarea = <T extends FieldValues>({ name, disabled = false, ...rest }: FormTextareaProps<T>) => {
    const { control } = useFormContext<T>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Textarea {...rest} disabled={disabled} onChange={onChange} value={value} error={error?.message?.toString()} />
            )}
        />
    );
};
