import { Stack, Title } from "@mantine/core";
import { createFileRoute, notFound, useLoaderData, useNavigate, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { MessageForm } from "shared/components/messages/MessageForm";
import { MessageStepper } from "shared/components/messages/MessageStepper";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { notify } from "shared/utils/notify";
import { graphql } from "src/gql";
import { match, P } from "ts-pattern";

const MessageDetailEditComponent = () => {
    const navigate = useNavigate();
    const params = useParams({ from: "/_auth/series/$seriesId/message/$messageId/edit" });
    const data = useLoaderData({ from: "/_auth/series/$seriesId/message/$messageId/edit" });

    const { mutate } = useGraphqlMutation({
        document: graphql(`
            mutation MessageEdit($id: UUID!, $params: UpdateMessageInfoInput!) {
                updateMessage(id: $id, params: $params) {
                    id
                }
            }
        `),
        onSuccess: async (res) => {
            notify.show.success({ message: "Message updated" });
            await navigate({
                to: "/series/$seriesId/message/$messageId/review",
                params: { seriesId: params.seriesId, messageId: res?.updateMessage.id ?? "" },
            });
        },
    });

    return (
        <Stack>
            <MessageStepper />
            <Title order={3}>Edit Message</Title>
            <MessageForm
                message={data.message}
                onSubmit={(data) => {
                    mutate({
                        id: params.messageId,
                        params: {
                            body: data.body,
                            emailBodyOverride: data.emailBodyOverride,
                            recipientGroups: data.recipientGroups,
                            recipientUsers: null,
                            severity: data.severity,
                            smsBodyOverride: data.smsBodyOverride,
                            subject: data.subject,
                            tags: null,
                        },
                    });
                }}
            />
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/series/$seriesId/message/$messageId/edit")({
    gcTime: 0,
    shouldReload: false,
    loader: async ({ abortController, params }) => {
        const data = await httpPostGraphql(
            graphql(`
                query MessageDetailEdit($id: UUID!) {
                    message(id: $id) {
                        id
                        subject
                        body
                        tags
                        publishedAt
                        severity
                        status
                        smsBodyOverride
                        emailBodyOverride
                        groupRecipients {
                            id
                            name
                        }
                        series {
                            id
                            description
                            expiry
                            title
                            type {
                                id
                            }
                        }
                    }
                }
            `),
            {
                id: params.messageId,
            },
            {
                signal: abortController.signal,
            },
        );

        return match(data?.message)
            .with(P.not(P.nullish), (message) => ({ message }))
            .otherwise(() => {
                throw notFound();
            });
    },
    component: MessageDetailEditComponent,
    meta: ({ loaderData }) => [
        {
            title: `Message ${loaderData.message.subject}`,
        },
    ],
});
