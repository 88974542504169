import { useMutation } from "@tanstack/react-query";
import { UseMutationOptions } from "@tanstack/react-query";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";

type AbstractedMutationOptions<TData = unknown, TVariables = unknown> = Omit<
    UseMutationOptions<TData, unknown, TVariables, unknown>,
    "throwOnError" | "mutationFn"
>;

export const useLogin = ({ onSuccess, onError, ...rest }: AbstractedMutationOptions<{ username: string }, { username: string }>) => {
    const { mutate: login, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: ({ username }) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ username });
                }, 300);
            });
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            notify.show.error({ message: "Failed to login" });
            logger.error(err);
        },
        ...rest,
    });

    return { login, ...mut };
};

export const useLogout = ({ onSuccess, onError, ...rest }: AbstractedMutationOptions<void, void>) => {
    const { mutate: logout, ...mut } = useMutation({
        throwOnError: false,
        mutationFn: () => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 300);
            });
        },
        onSuccess: (res, ...rest) => {
            onSuccess?.(res, ...rest);
        },
        onError: (err, ...rest) => {
            onError?.(err, ...rest);
            notify.show.error({ message: "Failed to logout" });
            logger.error(err);
        },
        ...rest,
    });

    return { logout, ...mut };
};
