/* eslint-disable no-console */
import { fromError, ZodError } from "zod-validation-error";

export const logger = {
    warnZod: (error: ZodError, ...optionalParams: unknown[]) => {
        logger.warn(fromError(error).toString(), ...optionalParams);
    },
    log: (message: unknown, ...optionalParams: unknown[]) => {
        console.log(message, ...optionalParams);
    },

    warn: (message: unknown, ...optionalParams: unknown[]) => {
        console.warn(message, ...optionalParams);
    },

    error: (message: unknown, ...optionalParams: unknown[]) => {
        console.error(message, ...optionalParams);
    },

    debug: (message: unknown, ...optionalParams: unknown[]) => {
        console.debug(message, ...optionalParams);
    },

    alert: (message: unknown) => {
        window.alert(message);
    },
};
