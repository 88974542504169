import { Button, Container, Group, Title } from "@mantine/core";
import { ErrorComponentProps } from "@tanstack/react-router";
import classes from "shared/components/global/AppError/AppError.module.css";
import { logger } from "shared/utils/logger";

export const AppError = (props: ErrorComponentProps) => {
    logger.error(props.error.message);

    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.label}>Oops!</div>
                <Title className={classes.title}>Something bad just happened...</Title>
                <Group justify="center" mt="lg">
                    <Button variant="white" size="md" onClick={window.location.reload}>
                        Refresh the page
                    </Button>
                </Group>
            </Container>
        </div>
    );
};
