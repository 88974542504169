import { Stack, Title } from "@mantine/core";
import { createFileRoute, useNavigate, useSearch } from "@tanstack/react-router";
import { MessageForm } from "shared/components/messages/MessageForm";
import { MessageStepper } from "shared/components/messages/MessageStepper";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { notify } from "shared/utils/notify";
import { graphql } from "src/gql";
import { z } from "zod";

const MessageCreateComponent = () => {
    const navigate = useNavigate();
    const search = useSearch({ from: "/_auth/series/message/create" });

    const { mutate: create } = useGraphqlMutation({
        document: graphql(`
            mutation MessageCreate($params: CreateMessageInfoInput!, $seriesParams: CreateMessageSeriesInfoInput!) {
                createMessage(params: $params, seriesParams: $seriesParams) {
                    id
                    series {
                        id
                    }
                }
            }
        `),
        onSuccess: async (res) => {
            notify.show.success({ message: "Message created" });
            await navigate({
                to: "/series/$seriesId/message/$messageId/review",
                params: { seriesId: res?.createMessage.series.id ?? "", messageId: res?.createMessage.id ?? "" },
            });
        },
    });

    const { mutate: createOnSeries } = useGraphqlMutation({
        document: graphql(`
            mutation MessageCreateOnSeries($params: CreateMessageInfoInput!, $seriesId: UUID!) {
                createMessageOnSeries(params: $params, seriesId: $seriesId) {
                    id
                    series {
                        id
                    }
                }
            }
        `),
        onSuccess: async (res) => {
            notify.show.success({ message: "Message created" });
            await navigate({
                to: "/series/$seriesId/message/$messageId/review",
                params: { seriesId: res?.createMessageOnSeries.series.id ?? "", messageId: res?.createMessageOnSeries.id ?? "" },
            });
        },
    });

    return (
        <Stack>
            <MessageStepper />
            <Title order={3}>Create Message</Title>
            <MessageForm
                seriesId={search.seriesId}
                onSubmit={(data) => {
                    const { description, expiry, title, ...params } = data;
                    const createParams = {
                        tags: null,
                        recipientUsers: null,
                        ...params,
                    };

                    {
                        search.seriesId
                            ? createOnSeries({ params: createParams, seriesId: search.seriesId })
                            : create({
                                  params: createParams,
                                  seriesParams: {
                                      description,
                                      expiry,
                                      title,
                                  },
                              });
                    }
                }}
            />
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/series/message/create")({
    validateSearch: z.object({
        seriesId: z.string().optional().catch(""),
    }),
    component: MessageCreateComponent,
    meta: () => [
        {
            title: "Message Create",
        },
    ],
});
