import { Select, SelectProps } from "@mantine/core";
import { Controller, FieldPath, FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

type FormSelectProps<T extends FieldValues> = {
    name: FieldPath<T>;
} & Omit<SelectProps, "onChange" | "value" | "id">;

export const FormSelect = <T extends FieldValues>({
    name,
    data,
    disabled = false,
    clearable = true,
    searchable = true,
    ...rest
}: FormSelectProps<T>) => {
    const { setValue, control } = useFormContext<T>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ fieldState: { error }, field: { value } }) => {
                return (
                    <Select
                        {...rest}
                        onChange={(e) => {
                            setValue(name, e as PathValue<T, Path<T>>, { shouldDirty: true });
                        }}
                        value={value}
                        data={data}
                        error={error?.message?.toString()}
                        clearable={clearable}
                        disabled={disabled}
                        searchable={searchable}
                    />
                );
            }}
        />
    );
};
