import { QueryCache, QueryClient } from "@tanstack/react-query";
import { logger } from "shared/utils/logger";

interface QueryCacheQuery {
    meta?: {
        errorMessage?: string;
        errorCallback?: () => void;
    };
}

export const queryClient = new QueryClient({
    // Default options are overridable on a per query/mutation basis
    defaultOptions: {
        queries: {
            retry: false,
            throwOnError: true,
            refetchOnWindowFocus: false,
        },
        mutations: {
            retry: false,
            throwOnError: true,
        },
    },
    // Please do not add a `onSuccess` handler to the queryCache. Whatever you're attempting to do is likely
    // an anti-pattern or abuse of the library. `useQuery` mental model should be that it is a remote state management hook,
    // if you _absolutely_ need to sync the remote state with local state (ex: zustand) then opt for an effect via `useEffect`.
    queryCache: new QueryCache({
        onError: (error, query: QueryCacheQuery) => {
            logger.error(error);
            query.meta?.errorCallback?.();
        },
    }),
});
