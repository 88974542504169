import { Stack } from "@mantine/core";
import { createFileRoute, notFound, useLoaderData, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { MessageGridItem } from "shared/components/messages/MessageGridItem";
import { useBreadcrumbs } from "shared/hooks/useBreadcrumbs";
import { graphql } from "src/gql";
import { match, P } from "ts-pattern";

const MessageDetailViewComponent = () => {
    const { Breadcrumbs } = useBreadcrumbs();
    const data = useLoaderData({ from: "/_auth/series/$seriesId/message/$messageId/" });
    const params = useParams({ from: "/_auth/series/$seriesId/message/$messageId/" });

    return (
        <Stack>
            <Breadcrumbs />
            <MessageGridItem message={data.message} seriesId={params.seriesId} />
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/series/$seriesId/message/$messageId/")({
    loader: async ({ abortController, params }) => {
        const data = await httpPostGraphql(
            graphql(`
                query MessageDetailView($id: UUID!) {
                    message(id: $id) {
                        id
                        subject
                        body
                        tags
                        status
                        publishedAt
                        scheduledFor
                    }
                }
            `),
            {
                id: params.messageId,
            },
            {
                signal: abortController.signal,
            },
        );

        return match(data?.message)
            .with(P.not(P.nullish), (message) => ({ message }))
            .otherwise(() => {
                throw notFound();
            });
    },
    component: MessageDetailViewComponent,
    meta: ({ loaderData }) => [
        {
            title: `Message ${loaderData.message.subject}`,
        },
    ],
});
