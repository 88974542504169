import { Link, RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import classes from "shared/components/form/RichTextContent.module.css";

export const RichTextContent = (props: { content: string }) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            Subscript,
            Highlight,
            TextAlign.configure({ types: ["heading", "paragraph"] }),
        ],
        editable: false,
        content: props.content,
    });

    return (
        <RichTextEditor editor={editor} styles={{ root: { border: "none" } }} fz="sm">
            <RichTextEditor.Content className={classes.content} onClick={(e) => e.stopPropagation()} />
        </RichTextEditor>
    );
};
