import { Stack, Text } from "@mantine/core";
import { Link, RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import SubScript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ReactNode } from "react";
import { Controller, FieldPath, FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

const RichTextEditorFEATURES = {
    bold: <RichTextEditor.Bold />,
    italic: <RichTextEditor.Italic />,
    underline: <RichTextEditor.Underline />,
    strikethrough: <RichTextEditor.Strikethrough />,
    "clear-formatting": <RichTextEditor.ClearFormatting />,
    highlight: <RichTextEditor.Highlight />,
    code: <RichTextEditor.Code />,
    h1: <RichTextEditor.H1 />,
    h2: <RichTextEditor.H2 />,
    h3: <RichTextEditor.H3 />,
    h4: <RichTextEditor.H4 />,
    h5: <RichTextEditor.H5 />,
    blockquote: <RichTextEditor.Blockquote />,
    hr: <RichTextEditor.Hr />,
    bulletlist: <RichTextEditor.BulletList />,
    orderedlist: <RichTextEditor.OrderedList />,
    superscript: <RichTextEditor.Superscript />,
    subscript: <RichTextEditor.Subscript />,
    link: <RichTextEditor.Link />,
    unlink: <RichTextEditor.Unlink />,
    "align-left": <RichTextEditor.AlignLeft />,
    "align-center": <RichTextEditor.AlignCenter />,
    "align-justify": <RichTextEditor.AlignJustify />,
    "align-right": <RichTextEditor.AlignRight />,
    undo: <RichTextEditor.Undo />,
    redo: <RichTextEditor.Redo />,
} satisfies Record<string, ReactNode>;

type RichTextEditorFeatureKey = keyof typeof RichTextEditorFEATURES;
type RichTextEditorFeatureGroup = RichTextEditorFeatureKey[];
type RichTextEditorFeatureSet = RichTextEditorFeatureGroup[];

const prefabFeatureSets = {
    "full-formatting+headings1-4+list+link+full-align+tx": [
        ["bold", "italic", "underline", "strikethrough", "clear-formatting", "highlight"],
        ["h1", "h2", "h3", "h4"],
        ["bulletlist", "orderedlist"],
        ["link", "unlink"],
        ["align-left", "align-center", "align-justify", "align-left"],
        ["undo", "redo"],
    ],
    "std-formatting+h1-2+list+link+std-align": [
        ["bold", "italic", "underline", "strikethrough", "highlight"],
        ["h1", "h2"],
        ["bulletlist", "orderedlist"],
        ["link", "unlink"],
        ["align-left", "align-center", "align-right"],
    ],
} satisfies Record<string, RichTextEditorFeatureSet>;

type RichTextEditorPreset = keyof typeof prefabFeatureSets;

type FormRichTextEditorProps<T extends FieldValues> = {
    name: FieldPath<T>;
    label?: string;
    placeholder?: string;
    features: RichTextEditorFeatureSet | RichTextEditorPreset;
};

const FeatureSet = (props: { featureset: RichTextEditorFeatureSet }) => {
    return props.featureset.map((group) => (
        <RichTextEditor.ControlsGroup key={group.map((set) => set).join(",")}>
            {group.map((featureKey, index) => (
                <div key={`${index}-${featureKey}`}>{RichTextEditorFEATURES[featureKey]}</div>
            ))}
        </RichTextEditor.ControlsGroup>
    ));
};

export const FormRichTextEditor = <T extends FieldValues>({ name, label, placeholder = "", features }: FormRichTextEditorProps<T>) => {
    const { control, setValue } = useFormContext<T>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ fieldState: { error }, field: { value, name } }) => {
                const editor = useEditor(
                    {
                        extensions: [
                            StarterKit,
                            Underline,
                            Link,
                            Superscript,
                            SubScript,
                            Highlight,
                            TextAlign.configure({ types: ["heading", "paragraph"] }),
                            Placeholder.configure({ placeholder }),
                        ],
                        content: value,
                        onUpdate({ editor }) {
                            setValue(name, editor.getHTML() as PathValue<T, Path<T>>);
                        },
                    },
                    [error],
                );

                return (
                    <>
                        {label && (
                            <Text fz="lg" fw={600}>
                                {label}
                            </Text>
                        )}
                        <Stack gap={0}>
                            <RichTextEditor
                                editor={editor}
                                style={{ borderColor: error ? "var(--mantine-color-error)" : undefined }}
                                fz="sm"
                            >
                                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                                    <FeatureSet featureset={typeof features == "string" ? prefabFeatureSets[features] : features} />
                                </RichTextEditor.Toolbar>
                                <RichTextEditor.Content />
                            </RichTextEditor>
                            <Text size="sm" c="var(--mantine-color-error)">
                                {error?.message?.toString()}
                            </Text>
                        </Stack>
                    </>
                );
            }}
        />
    );
};
