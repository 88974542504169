import { ReactNode } from "react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { logger } from "shared/utils/logger";

type AppFormProps<T extends Record<string, unknown>> = {
    children: ReactNode;
    form: UseFormReturn<T, unknown, undefined>;
    onSubmit: SubmitHandler<T>;
};

export const AppForm = <T extends Record<string, unknown>>({ children, form, onSubmit }: AppFormProps<T>) => {
    return (
        <FormProvider {...form}>
            <form onSubmit={(e) => void form.handleSubmit(onSubmit, logger.warn)(e)}>{children}</form>
        </FormProvider>
    );
};
