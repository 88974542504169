import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import "@mantine/dates/styles.css";
import "src/main.css";

import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { useAuthStore } from "shared/stores/authStore";
import { cssVariablesResolver, theme } from "shared/utils/theme";
import { queryClient } from "src/queryClient";
import { router } from "src/router";

dayjs.extend(localizedFormat);

const App = () => {
    const auth = useAuthStore();

    return (
        <>
            <RouterProvider router={router} context={{ auth }} />
        </>
    );
};

const rootElement = document.getElementById("app")!;

if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <ColorSchemeScript defaultColorScheme="auto" />
                <MantineProvider defaultColorScheme="auto" cssVariablesResolver={cssVariablesResolver} theme={theme}>
                    <ModalsProvider>
                        <Notifications />
                        <App />
                    </ModalsProvider>
                </MantineProvider>
            </QueryClientProvider>
        </StrictMode>,
    );
}
