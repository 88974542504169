import { Stepper } from "@mantine/core";
import { Icon, IconClipboardText, IconEdit, IconProgress } from "@tabler/icons-react";
import { ParseRoute, useNavigate, useRouterState } from "@tanstack/react-router";
import { type routeTree } from "routeTree.gen";

const steps: Record<
    ParseRoute<typeof routeTree>["id"][number],
    {
        icon: Icon;
        route: ParseRoute<typeof routeTree>["fullPath"];
        label: string;
    }
> = {
    "/_auth/series/message/create": {
        icon: IconProgress,
        route: "",
        label: "Create",
    },
    "/_auth/series/$id/message/$messageId/edit": {
        icon: IconEdit,
        route: "/series/$seriesId/message/$messageId/edit",
        label: "Edit",
    },
    "/_auth/series/$id/message/$messageId/review": {
        icon: IconClipboardText,
        route: "/series/$seriesId/message/$messageId/review",
        label: "Review",
    },
};

export const MessageStepper = () => {
    const router = useRouterState();
    const activePath = router.matches.at(-1)?.routeId;
    const index = activePath ? Object.keys(steps).indexOf(activePath) : 0;
    const navigate = useNavigate();

    return (
        <Stepper active={index !== -1 ? index : 0} onStepClick={(index) => void navigate({ to: steps[Object.keys(steps)[index]].route })}>
            {Object.keys(steps).map((step) => {
                const IconComponent = steps[step].icon;

                return (
                    <Stepper.Step
                        key={step}
                        icon={<IconComponent size={20} />}
                        label={steps[step].label}
                        allowStepSelect={index !== 0}
                        allowStepClick={index !== 0}
                    />
                );
            })}
        </Stepper>
    );
};
