import { Group, Paper, Stack, Text, Title } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { RichTextContent } from "shared/components/form/RichTextContent";
import { MessageSeriesStatusBadge } from "shared/components/messages/MessageSeriesStatusBadge";
import classes from "shared/components/messages/SeriesGridItem.module.css";
import { useColorMode } from "shared/hooks/useColorMode";
import { SeriesTimelineQuery } from "src/gql/graphql";
import { match, P } from "ts-pattern";

export const SeriesGridItem = ({ messageSeries }: { messageSeries: SeriesTimelineQuery["timeline"]["series"][number] }) => {
    const { isDark } = useColorMode();
    const message = messageSeries.messages.length > 0 ? messageSeries.messages[0] : null;

    const title = match(messageSeries)
        .with({ title: P.string }, (series) => series.title)
        .otherwise(() => {
            return message?.subject;
        });

    const body = match(messageSeries)
        .with({ description: P.string }, (series) => <Text>{series.description}</Text>)
        .otherwise(() => {
            return match({ body: message?.body, smsBody: message?.smsBodyOverride, emailBody: message?.emailBodyOverride })
                .with({ smsBody: P.string }, ({ smsBody }) => <RichTextContent content={smsBody} />)
                .with({ emailBody: P.string }, ({ emailBody }) => <RichTextContent content={emailBody} />)
                .with({ body: P.string }, ({ body }) => <RichTextContent content={body} />)
                .otherwise(() => <></>);
        });

    return (
        <Link as="span" to="/series/$seriesId" params={{ seriesId: messageSeries.id }} className={classes.link}>
            <Paper shadow="xs" p="md" withBorder={isDark} className={classes.paper}>
                <Stack>
                    <Group justify="space-between">
                        <Group>
                            <Title order={5}>{title}</Title>
                            <MessageSeriesStatusBadge status={messageSeries.status} />
                        </Group>
                        {match(messageSeries)
                            .with({ expiry: P.number }, (series) => <Text>{dayjs(new Date(series.expiry)).format("LT L")}</Text>)
                            .otherwise(() => (
                                <></>
                            ))}
                    </Group>
                    {body}
                </Stack>
            </Paper>
        </Link>
    );
};
