import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Checkbox, ComboboxItem, Divider, Group, Radio, Stack, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { userGroupKeys } from "shared/api/queryKeys";
import { AppForm } from "shared/components/form/AppForm";
import { FormMultiSelect } from "shared/components/form/FormMultiSelect";
import { FormRichTextEditor } from "shared/components/form/FormRichTextEditor";
import { FormSelect } from "shared/components/form/FormSelect";
import { FormTextarea } from "shared/components/form/FormTextarea";
import { FormTextInput } from "shared/components/form/FormTextInput";
import { useGraphqlQuery } from "shared/hooks/useGraphql";
import { enumToArray } from "shared/utils/enum";
import { graphql } from "src/gql";
import { MessageDetailEditQuery } from "src/gql/graphql";
import { CreateMessageInfoInputSchema, CreateMessageSeriesInfoInputSchema, MessageSeverity } from "src/gql/zod";
import { match } from "ts-pattern";
import { z } from "zod";

const CreateMessageParamsSchema = z.intersection(CreateMessageInfoInputSchema(), CreateMessageSeriesInfoInputSchema());
type CreateMessageParams = z.infer<typeof CreateMessageParamsSchema>;

type MessageFormProps = {
    message?: MessageDetailEditQuery["message"];
    seriesId?: string;
    onSubmit: SubmitHandler<CreateMessageParams>;
};

export const MessageForm = ({ message, seriesId, onSubmit }: MessageFormProps) => {
    const richTextEditorFeatures = "full-formatting+headings1-4+list+link+full-align+tx";
    const [hasCustomSms, setHasCustomSms] = useState(Boolean(message?.smsBodyOverride));
    const [hasCustomEmail, setHasCustomEmail] = useState(Boolean(message?.emailBodyOverride));
    const messageHasSeriesAttributes = Boolean(message?.series.title) || Boolean(message?.series.description);
    const [messageType, setMessageType] = useState<"single" | "series">(messageHasSeriesAttributes ? "series" : "single");
    const isEditing = Boolean(message);

    const form = useForm<CreateMessageParams>({
        mode: "onSubmit",
        resolver: zodResolver(CreateMessageParamsSchema),
        defaultValues: {
            subject: message?.subject,
            smsBodyOverride: message?.smsBodyOverride,
            emailBodyOverride: message?.emailBodyOverride,
            body: message?.body,
            severity: message?.severity,
            recipientGroups: message?.groupRecipients?.map((group) => group.id),
            description: message?.series.description,
            expiry: message?.series.expiry,
            title: message?.series.title,
        },
    });

    const { data } = useGraphqlQuery(
        userGroupKeys.queries(),
        graphql(`
            query MessageCreateUserGroups {
                userGroups {
                    id
                    name
                }
            }
        `),
        {},
    );

    const userGroups =
        data?.userGroups.map((group) => ({
            label: group.name,
            value: group.id,
        })) ?? ([] satisfies ComboboxItem[]);

    return (
        <AppForm<CreateMessageParams>
            form={form}
            onSubmit={(data) =>
                onSubmit({
                    ...data,
                    smsBodyOverride: hasCustomSms ? data.smsBodyOverride : null,
                    emailBodyOverride: hasCustomEmail ? data.emailBodyOverride : null,
                    title: messageType === "series" ? data.title : null,
                    description: messageType === "series" ? data.description : null,
                })
            }
        >
            <Stack>
                {!Boolean(seriesId) && (
                    <Radio.Group value={messageType} onChange={(val) => setMessageType(val as "single" | "series")}>
                        <Group gap="lg">
                            <Radio value="single" label="Single Message" disabled={isEditing} />
                            <Radio value="series" label="Message Series" disabled={isEditing} />
                        </Group>
                    </Radio.Group>
                )}

                {match(messageType)
                    .with("series", () => (
                        <>
                            <FormTextInput<CreateMessageParams> name="title" label="Series Title" disabled={isEditing} />
                            <FormTextarea<CreateMessageParams> name="description" label="Series Description" disabled={isEditing} />
                        </>
                    ))
                    .otherwise(() => (
                        <></>
                    ))}

                <FormTextInput<CreateMessageParams> name="subject" label="Subject" />
                <Checkbox
                    label="Custom SMS Message"
                    checked={hasCustomSms}
                    onChange={(event) => setHasCustomSms(event.currentTarget.checked)}
                />
                {match({ hasCustomSms, smsBodyOverride: form.getValues("smsBodyOverride") })
                    .with({ hasCustomSms: true }, () => (
                        <FormRichTextEditor<CreateMessageParams> name="smsBodyOverride" features={richTextEditorFeatures} />
                    ))
                    .otherwise(() => (
                        <></>
                    ))}
                <Checkbox
                    label="Custom Email"
                    checked={hasCustomEmail}
                    onChange={(event) => setHasCustomEmail(event.currentTarget.checked)}
                />
                {match({ hasCustomEmail, emailBodyOverride: form.getValues("emailBodyOverride") })
                    .with({ hasCustomEmail: true }, () => (
                        <FormRichTextEditor<CreateMessageParams> name="emailBodyOverride" features={richTextEditorFeatures} />
                    ))
                    .otherwise(() => (
                        <></>
                    ))}

                <FormRichTextEditor<CreateMessageParams> name="body" features={richTextEditorFeatures} />
                <FormSelect<CreateMessageParams>
                    name="severity"
                    label="Severity"
                    data={enumToArray(MessageSeverity).map((sev) => ({
                        label: sev,
                        value: sev,
                    }))}
                />
                <Divider />
                <Title order={3}>Audience</Title>
                <FormMultiSelect<CreateMessageParams> name="recipientGroups" label="Groups" data={userGroups} />
                <Group justify="flex-end">
                    <Button type="submit" rightSection={<IconArrowRight />}>
                        Next
                    </Button>
                </Group>
            </Stack>
        </AppForm>
    );
};
