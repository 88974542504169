import { NotificationData, notifications } from "@mantine/notifications";
import { IconCheck, IconInfoCircle, IconX } from "@tabler/icons-react";

export const notify = {
    ...notifications,
    show: {
        error: (props: NotificationData) => {
            notifications.show({
                color: "red",
                title: "Error",
                icon: <IconX size={20} />,
                ...props,
            });
        },
        info: (props: NotificationData) => {
            notifications.show({
                color: "blue",
                title: "Info",
                icon: <IconInfoCircle size={20} />,
                ...props,
            });
        },
        success: (props: NotificationData) => {
            notifications.show({
                color: "green",
                title: "Success",
                icon: <IconCheck size={20} />,
                ...props,
            });
        },
    },
} as const;
