import { Group, Menu, SimpleGrid, Stack, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { createFileRoute, Link, useLoaderData } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { SeriesGridItem } from "shared/components/messages/SeriesGridItem";
import { graphql } from "src/gql";
import { match, P } from "ts-pattern";

const SeriesComponent = () => {
    const data = useLoaderData({ from: "/_auth/series/" });

    const openModal = () =>
        modals.open({
            title: "Select Template",
            size: "lg",
            centered: true,
            children: (
                <SimpleGrid cols={{ base: 2 }} spacing={{ base: "md" }} verticalSpacing={{ base: "md" }}>
                    TODO: Templates
                </SimpleGrid>
            ),
        });

    return (
        <Stack>
            <Group justify="space-between">
                <Title order={2}>Message Series</Title>
            </Group>
            <SimpleGrid cols={{ base: 1 }} spacing={{ base: "md" }} verticalSpacing={{ base: "md" }}>
                <>
                    {match(data.series.length)
                        .with(P.select(P.when((n) => n > 0)), () =>
                            data.series.map((it) => <SeriesGridItem key={it.id} messageSeries={it} />),
                        )
                        .otherwise(() => (
                            <Title order={4}>No Messages</Title>
                        ))}
                </>
            </SimpleGrid>
            <FloatingActionButton>
                <Link to="/series/message/create">
                    <Menu.Item>New Message</Menu.Item>
                </Link>
                <Menu.Item onClick={openModal}>From Template</Menu.Item>
            </FloatingActionButton>
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/series/")({
    gcTime: 0,
    shouldReload: false,
    loader: async ({ abortController }) => {
        const data = await httpPostGraphql(
            graphql(`
                query SeriesTimeline($limit: Int!) {
                    timeline {
                        series {
                            id
                            title
                            description
                            status
                            expiry
                            messages(limit: $limit) {
                                id
                                subject
                                body
                                smsBodyOverride
                                emailBodyOverride
                            }
                        }
                    }
                }
            `),
            {
                limit: 1,
            },
            {
                signal: abortController.signal,
            },
        );

        return {
            series: data?.timeline.series ?? [],
        };
    },
    component: SeriesComponent,
    meta: () => [
        {
            title: "Series",
        },
    ],
});
