import { Breadcrumbs } from "@mantine/core";
import { LinkProps, useRouter } from "@tanstack/react-router";
import { useCallback } from "react";
import { Breadcrumb } from "shared/components/global/Breadcrumb/Breadcrumb";

export const useBreadcrumbs = () => {
    const router = useRouter();

    const items = router.state.matches
        .map((match) => {
            const { meta, pathname } = match;
            return {
                title: meta?.find((tag) => tag.title)?.title,
                to: pathname as LinkProps["to"],
            };
        })
        .filter((crumb) => Boolean(crumb.title));

    const Component = useCallback(() => {
        return (
            <Breadcrumbs>
                {items.map((crumb) => (
                    <Breadcrumb key={crumb.title} to={crumb.to}>
                        {crumb.title}
                    </Breadcrumb>
                ))}
            </Breadcrumbs>
        );
    }, [items]);

    return { items, Breadcrumbs: Component };
};
