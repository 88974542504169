import { ActionIcon, AppShell, Burger, Group, Title, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserCircle } from "@tabler/icons-react";
import { createFileRoute, Link, Outlet, redirect } from "@tanstack/react-router";
import { Sidebar, sidebarBreakpoint } from "shared/components/global/Sidebar/Sidebar";
import { useColorMode } from "shared/hooks/useColorMode";
import { BryxLogo } from "src/assets/BryxLogo";

const AuthLayout = () => {
    const [opened, { toggle, close }] = useDisclosure();
    const logo = import.meta.env.VITE_ORG_LOGO_SOURCE;
    const title = import.meta.env.VITE_ORG_TITLE;
    const logoWidth = "30px";
    const { isDark } = useColorMode();

    return (
        <AppShell
            header={{ height: 60 }}
            navbar={{ width: 80, breakpoint: sidebarBreakpoint, collapsed: { mobile: !opened } }}
            padding="md"
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    <Burger opened={opened} onClick={toggle} hiddenFrom={sidebarBreakpoint} size="sm" />
                    <Group pl="xs">
                        <Group>
                            {Boolean(logo) ? <img src={logo} width={logoWidth} /> : <BryxLogo width={logoWidth} />}
                            <Title order={2} c="var(--mantine-primary-color-filled)">
                                {Boolean(title) ? title : "EMNS"}
                            </Title>
                        </Group>
                    </Group>
                    <Link to="/profile">
                        <Tooltip label="Profile" position="bottom" transitionProps={{ duration: 0 }} color={isDark ? "gray" : undefined}>
                            <ActionIcon variant="filled" aria-label="Profile" radius="lg" size="lg">
                                <IconUserCircle size={20} stroke={1.5} />
                            </ActionIcon>
                        </Tooltip>
                    </Link>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md">
                <Sidebar close={close} />
            </AppShell.Navbar>
            <AppShell.Main>
                <section>
                    <Outlet />
                </section>
            </AppShell.Main>
        </AppShell>
    );
};

/** @public */
export const Route = createFileRoute("/_auth")({
    beforeLoad: ({ context, location }) => {
        if (!context.auth.session) {
            throw redirect({
                to: "/login",
                search: {
                    redirect: location.href,
                },
            });
        }
    },
    component: AuthLayout,
});
