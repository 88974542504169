import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Group, Modal, ModalProps, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconClock } from "@tabler/icons-react";
import { createFileRoute, notFound, useLoaderData, useNavigate, useParams } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { httpPostGraphql } from "shared/api/httpClient";
import { AppForm } from "shared/components/form/AppForm";
import { FormDateTimepicker } from "shared/components/form/FormDateTimePicker";
import { SplitButton } from "shared/components/global/SplitButton/SplitButton";
import { MessageReview } from "shared/components/messages/MessageReview";
import { MessageStepper } from "shared/components/messages/MessageStepper";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { graphql } from "src/gql";
import { MessageStatus } from "src/gql/graphql";
import { MutationScheduleMessageArgs } from "src/gql/zod";
import { match, P } from "ts-pattern";
import { z } from "zod";

type ScheduleMessageState = Omit<MutationScheduleMessageArgs, "epochMs"> & {
    epochMs: Date;
};

const ScheduleModal = ({ opened, onClose, ...rest }: ModalProps) => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const data = useLoaderData({ from: "/_auth/series/$seriesId/message/$messageId/review" });
    const navigate = useNavigate();
    const params = useParams({ from: "/_auth/series/$seriesId/message/$messageId/review" });
    const { mutate: scheduleMessage } = useGraphqlMutation({
        document: graphql(`
            mutation MessageReviewScheduleMessage($id: UUID!, $epochMs: Long!) {
                scheduleMessage(id: $id, epochMs: $epochMs) {
                    id
                }
            }
        `),
        onSuccess: async () => {
            notify.show.success({ message: "Message scheduled" });
            await navigate({ to: "/series/$seriesId", params: { seriesId: params.seriesId } });
        },
    });

    const form = useForm<ScheduleMessageState>({
        mode: "onSubmit",
        resolver: zodResolver(
            z.object({
                id: z.string(),
                epochMs: z.date().refine((data) => data > now, { message: "Date must be in the future." }),
            }),
        ),
        defaultValues: {
            id: data.message.id,
            epochMs: tomorrow,
        },
    });

    return (
        <Modal {...rest} title="Schedule Modal" opened={opened} onClose={onClose} centered>
            <AppForm<ScheduleMessageState>
                form={form}
                onSubmit={(data) =>
                    scheduleMessage({
                        id: data.id,
                        epochMs: data.epochMs.valueOf(),
                    })
                }
            >
                <Stack>
                    <FormDateTimepicker<MutationScheduleMessageArgs> name="epochMs" minDate={now} />

                    <Group justify="flex-end">
                        <Button variant="default" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" leftSection={<IconClock size={16} />}>
                            Schedule
                        </Button>
                    </Group>
                </Stack>
            </AppForm>
        </Modal>
    );
};

const MessageDetailReviewComponent = () => {
    const navigate = useNavigate();
    const data = useLoaderData({ from: "/_auth/series/$seriesId/message/$messageId/review" });
    const params = useParams({ from: "/_auth/series/$seriesId/message/$messageId/review" });
    const [opened, { open, close }] = useDisclosure();

    const openSendModal = () =>
        modals.openConfirmModal({
            centered: true,
            title: "Are you sure?",
            children: (
                <Text size="sm">
                    You are about to send a prettttty important message to a whole lotta folks. Are you sure you want to send?
                </Text>
            ),
            labels: { confirm: "Send", cancel: "Cancel" },
            onConfirm: () =>
                publishMessage({
                    id: data.message.id,
                }),
        });

    const { mutate: publishMessage } = useGraphqlMutation({
        document: graphql(`
            mutation MessageReviewPublishMessage($id: UUID!) {
                publishMessage(id: $id) {
                    id
                }
            }
        `),
        onSuccess: async () => {
            notify.show.success({ message: "Message published" });
            await navigate({ to: "/series/$seriesId", params: { seriesId: params.seriesId } });
        },
    });

    return (
        <>
            <ScheduleModal opened={opened} onClose={close} />
            <Stack>
                <MessageStepper />
                <Title order={3}>Review</Title>
                <MessageReview message={data.message} />
                <Group justify="flex-end">
                    <SplitButton
                        onClick={openSendModal}
                        variant="default"
                        menu={{
                            "Schedule Message": { fn: open },
                        }}
                    >
                        Send
                    </SplitButton>
                </Group>
            </Stack>
        </>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/series/$seriesId/message/$messageId/review")({
    gcTime: 0,
    shouldReload: false,
    loader: async ({ abortController, params }) => {
        const data = await httpPostGraphql(
            graphql(`
                query MessageDetailReview($id: UUID!) {
                    message(id: $id) {
                        id
                        subject
                        body
                        tags
                        publishedAt
                        severity
                        status
                        smsBodyOverride
                        emailBodyOverride
                        groupRecipients {
                            name
                            memberCount
                        }
                        recipientCount
                        series {
                            id
                            description
                            expiry
                            title
                        }
                    }
                }
            `),
            {
                id: params.messageId,
            },
            {
                signal: abortController.signal,
            },
        );
        const status = data?.message?.status;

        if (status !== MessageStatus.Draft) {
            logger.warn(`Path parameter id (${params.messageId}) is not in a reviewable status`);

            throw notFound();
        }

        return match(data?.message)
            .with(P.not(P.nullish), (message) => ({ message }))
            .otherwise(() => {
                throw notFound();
            });
    },
    component: MessageDetailReviewComponent,
    meta: () => [
        {
            title: "Message Review",
        },
    ],
});
