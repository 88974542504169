import { Group, Menu, SimpleGrid, Stack, Title } from "@mantine/core";
import { createFileRoute, Link, notFound, useLoaderData, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { MessageGridItem } from "shared/components/messages/MessageGridItem";
import { graphql } from "src/gql";
import { MessageSeriesDetailViewQuery } from "src/gql/graphql";
import { match, P } from "ts-pattern";

const SeriesViewComponent = () => {
    const params = useParams({ from: "/_auth/series/$seriesId/" });
    const data = useLoaderData({ from: "/_auth/series/$seriesId/" });

    return (
        <Stack>
            <Group justify="space-between">
                <Title order={2}>Messages</Title>
            </Group>
            <SimpleGrid cols={{ base: 1 }} spacing={{ base: "md" }} verticalSpacing={{ base: "md" }}>
                <>
                    {match(data.messages.length)
                        .with(P.select(P.when((n) => n > 0)), () =>
                            data.messages.map((it) => <MessageGridItem key={it.id} message={it} seriesId={params.seriesId} />),
                        )
                        .otherwise(() => (
                            <Title order={4}>No Messages</Title>
                        ))}
                </>
            </SimpleGrid>
            <FloatingActionButton>
                <Link to="/series/message/create" search={{ seriesId: params.seriesId }}>
                    <Menu.Item>New Message</Menu.Item>
                </Link>
            </FloatingActionButton>
        </Stack>
    );
};

const getTitleFromSeries = (data: MessageSeriesDetailViewQuery["messageSeries"]) => {
    if (!!data.title) {
        return data.title;
    }

    const message = data.messages[0];

    if (!message) {
        return "";
    }

    return message.subject;
};

/** @public */
export const Route = createFileRoute("/_auth/series/$seriesId/")({
    gcTime: 0,
    shouldReload: false,
    loader: async ({ abortController, params }) => {
        const data = await httpPostGraphql(
            graphql(`
                query MessageSeriesDetailView($id: UUID!) {
                    messageSeries(id: $id) {
                        title
                        messages {
                            id
                            subject
                            body
                            status
                            publishedAt
                            scheduledFor
                            smsBodyOverride
                            emailBodyOverride
                        }
                    }
                }
            `),
            {
                id: params.seriesId,
            },
            {
                signal: abortController.signal,
            },
        );

        if (!data.messageSeries) {
            throw notFound();
        }

        return data.messageSeries;
    },
    component: SeriesViewComponent,
    meta: ({ loaderData }) => [
        {
            title: getTitleFromSeries(loaderData),
        },
    ],
});
