import { Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconLogout, IconMessage } from "@tabler/icons-react";
import { ParseRoute, useNavigate, useRouter } from "@tanstack/react-router";
import type { routeTree } from "routeTree.gen";
import { useLogout } from "shared/api/authApi";
import { SidebarItem } from "shared/components/global/Sidebar/SidebarItem";
import { SidebarLink } from "shared/components/global/Sidebar/SidebarLink";
import { SidebarMobileItem } from "shared/components/global/Sidebar/SidebarMobileItem";
import { useAuthStore } from "shared/stores/authStore";

type SidebarLinkData = {
    icon: typeof IconMessage;
    label: string;
    to: ParseRoute<typeof routeTree>["fullPath"];
};

const sidebarLinkData: SidebarLinkData[] = [{ icon: IconMessage, label: "Series", to: "/series" }];

export const sidebarBreakpoint = "md";
const sidebarBreakpointMediaQuery = "(max-width: 62em)"; // 62em is default "md" from above

export const Sidebar = ({ close }: { close: () => void }) => {
    const router = useRouter();
    const navigate = useNavigate();
    const { resetStore } = useAuthStore.getState();
    const isMobile = useMediaQuery(sidebarBreakpointMediaQuery);

    const { logout } = useLogout({
        onSuccess: async () => {
            resetStore();
            await router.invalidate();
            await navigate({ to: "/" });
        },
    });

    const links = sidebarLinkData.map((link) => (
        <SidebarLink {...link} key={link.label} to={link.to} mobile={isMobile} onClick={isMobile ? close : undefined} />
    ));

    return (
        <>
            <Stack component="nav" justify="space-between" h="100%" hiddenFrom={sidebarBreakpoint}>
                <Stack justify="center" gap="sm">
                    {links}
                </Stack>

                <Stack justify="center" gap="sm">
                    <SidebarMobileItem icon={IconLogout} label="Logout" onClick={logout} />
                </Stack>
            </Stack>
            <Stack component="nav" justify="space-between" h="100%" visibleFrom={sidebarBreakpoint}>
                <Stack justify="center" gap={0}>
                    {links}
                </Stack>

                <Stack justify="center" gap={0}>
                    <SidebarItem icon={IconLogout} label="Logout" onClick={logout} />
                </Stack>
            </Stack>
        </>
    );
};
