import { TextInput, TextInputProps } from "@mantine/core";
import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

type FormTextInputProps<T extends FieldValues> = {
    name: FieldPath<T>;
} & TextInputProps;

export const FormTextInput = <T extends FieldValues>({ name, disabled = false, onChange, ...rest }: FormTextInputProps<T>) => {
    const { control } = useFormContext<T>();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange: rhfOnChange, value }, fieldState: { error } }) => (
                <TextInput
                    {...rest}
                    disabled={disabled}
                    onChange={(e) => {
                        onChange?.(e);
                        rhfOnChange(e);
                    }}
                    value={value}
                    error={error?.message?.toString()}
                />
            )}
        />
    );
};
