import { createTheme, CSSVariablesResolver, DEFAULT_THEME, mergeMantineTheme } from "@mantine/core";

const themeOverride = createTheme({
    primaryColor: import.meta.env.VITE_ORG_PRIMARY_COLOR ?? "red",
    fontFamily: "Open Sans",
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);

export const cssVariablesResolver: CSSVariablesResolver = () => ({
    variables: {},
    light: {},
    dark: {},
});
