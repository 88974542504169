import { createFileRoute, redirect } from "@tanstack/react-router";

/** @public */
export const Route = createFileRoute("/")({
    beforeLoad: ({ context }) => {
        if (context.auth.session) {
            throw redirect({
                to: "/series",
            });
        }

        throw redirect({
            to: "/login",
        });
    },
});
