import { Badge } from "@mantine/core";
import { MessageNode, MessageStatus } from "src/gql/graphql";
import { match } from "ts-pattern";

export const MessageStatusBadge = ({ status }: Pick<MessageNode, "status">) => {
    return match(status)
        .with(MessageStatus.Draft, (status) => <Badge color="yellow">{status}</Badge>)
        .with(MessageStatus.Published, (status) => <Badge color="green">{status}</Badge>)
        .with(MessageStatus.Scheduled, (status) => <Badge color="indigo">{status}</Badge>)
        .exhaustive();
};
