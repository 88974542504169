const queryKeyFactory = <const T extends string>(namespace: T) => {
    const queryKey = {
        all: [namespace],
        queries: () => [...queryKey.all, "query"],
        details: () => [...queryKey.all, "detail"],
        detail: (id: string) => [...queryKey.details(), id],
    } as const;

    return queryKey;
};

export const userGroupKeys = queryKeyFactory("userGroups");
