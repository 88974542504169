/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n                query MessageSeriesDetailView($id: UUID!) {\n                    messageSeries(id: $id) {\n                        title\n                        messages {\n                            id\n                            subject\n                            body\n                            status\n                            publishedAt\n                            scheduledFor\n                            smsBodyOverride\n                            emailBodyOverride\n                        }\n                    }\n                }\n            ": types.MessageSeriesDetailViewDocument,
    "\n            mutation MessageEdit($id: UUID!, $params: UpdateMessageInfoInput!) {\n                updateMessage(id: $id, params: $params) {\n                    id\n                }\n            }\n        ": types.MessageEditDocument,
    "\n                query MessageDetailEdit($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            id\n                            name\n                        }\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                            type {\n                                id\n                            }\n                        }\n                    }\n                }\n            ": types.MessageDetailEditDocument,
    "\n                query MessageDetailView($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        status\n                        publishedAt\n                        scheduledFor\n                    }\n                }\n            ": types.MessageDetailViewDocument,
    "\n            mutation MessageReviewScheduleMessage($id: UUID!, $epochMs: Long!) {\n                scheduleMessage(id: $id, epochMs: $epochMs) {\n                    id\n                }\n            }\n        ": types.MessageReviewScheduleMessageDocument,
    "\n            mutation MessageReviewPublishMessage($id: UUID!) {\n                publishMessage(id: $id) {\n                    id\n                }\n            }\n        ": types.MessageReviewPublishMessageDocument,
    "\n                query MessageDetailReview($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            name\n                            memberCount\n                        }\n                        recipientCount\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                        }\n                    }\n                }\n            ": types.MessageDetailReviewDocument,
    "\n                query SeriesTimeline($limit: Int!) {\n                    timeline {\n                        series {\n                            id\n                            title\n                            description\n                            status\n                            expiry\n                            messages(limit: $limit) {\n                                id\n                                subject\n                                body\n                                smsBodyOverride\n                                emailBodyOverride\n                            }\n                        }\n                    }\n                }\n            ": types.SeriesTimelineDocument,
    "\n            mutation MessageCreate($params: CreateMessageInfoInput!, $seriesParams: CreateMessageSeriesInfoInput!) {\n                createMessage(params: $params, seriesParams: $seriesParams) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        ": types.MessageCreateDocument,
    "\n            mutation MessageCreateOnSeries($params: CreateMessageInfoInput!, $seriesId: UUID!) {\n                createMessageOnSeries(params: $params, seriesId: $seriesId) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        ": types.MessageCreateOnSeriesDocument,
    "\n            query MessageCreateUserGroups {\n                userGroups {\n                    id\n                    name\n                }\n            }\n        ": types.MessageCreateUserGroupsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                query MessageSeriesDetailView($id: UUID!) {\n                    messageSeries(id: $id) {\n                        title\n                        messages {\n                            id\n                            subject\n                            body\n                            status\n                            publishedAt\n                            scheduledFor\n                            smsBodyOverride\n                            emailBodyOverride\n                        }\n                    }\n                }\n            "): (typeof documents)["\n                query MessageSeriesDetailView($id: UUID!) {\n                    messageSeries(id: $id) {\n                        title\n                        messages {\n                            id\n                            subject\n                            body\n                            status\n                            publishedAt\n                            scheduledFor\n                            smsBodyOverride\n                            emailBodyOverride\n                        }\n                    }\n                }\n            "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            mutation MessageEdit($id: UUID!, $params: UpdateMessageInfoInput!) {\n                updateMessage(id: $id, params: $params) {\n                    id\n                }\n            }\n        "): (typeof documents)["\n            mutation MessageEdit($id: UUID!, $params: UpdateMessageInfoInput!) {\n                updateMessage(id: $id, params: $params) {\n                    id\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                query MessageDetailEdit($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            id\n                            name\n                        }\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                            type {\n                                id\n                            }\n                        }\n                    }\n                }\n            "): (typeof documents)["\n                query MessageDetailEdit($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            id\n                            name\n                        }\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                            type {\n                                id\n                            }\n                        }\n                    }\n                }\n            "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                query MessageDetailView($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        status\n                        publishedAt\n                        scheduledFor\n                    }\n                }\n            "): (typeof documents)["\n                query MessageDetailView($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        status\n                        publishedAt\n                        scheduledFor\n                    }\n                }\n            "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            mutation MessageReviewScheduleMessage($id: UUID!, $epochMs: Long!) {\n                scheduleMessage(id: $id, epochMs: $epochMs) {\n                    id\n                }\n            }\n        "): (typeof documents)["\n            mutation MessageReviewScheduleMessage($id: UUID!, $epochMs: Long!) {\n                scheduleMessage(id: $id, epochMs: $epochMs) {\n                    id\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            mutation MessageReviewPublishMessage($id: UUID!) {\n                publishMessage(id: $id) {\n                    id\n                }\n            }\n        "): (typeof documents)["\n            mutation MessageReviewPublishMessage($id: UUID!) {\n                publishMessage(id: $id) {\n                    id\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                query MessageDetailReview($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            name\n                            memberCount\n                        }\n                        recipientCount\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                        }\n                    }\n                }\n            "): (typeof documents)["\n                query MessageDetailReview($id: UUID!) {\n                    message(id: $id) {\n                        id\n                        subject\n                        body\n                        tags\n                        publishedAt\n                        severity\n                        status\n                        smsBodyOverride\n                        emailBodyOverride\n                        groupRecipients {\n                            name\n                            memberCount\n                        }\n                        recipientCount\n                        series {\n                            id\n                            description\n                            expiry\n                            title\n                        }\n                    }\n                }\n            "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                query SeriesTimeline($limit: Int!) {\n                    timeline {\n                        series {\n                            id\n                            title\n                            description\n                            status\n                            expiry\n                            messages(limit: $limit) {\n                                id\n                                subject\n                                body\n                                smsBodyOverride\n                                emailBodyOverride\n                            }\n                        }\n                    }\n                }\n            "): (typeof documents)["\n                query SeriesTimeline($limit: Int!) {\n                    timeline {\n                        series {\n                            id\n                            title\n                            description\n                            status\n                            expiry\n                            messages(limit: $limit) {\n                                id\n                                subject\n                                body\n                                smsBodyOverride\n                                emailBodyOverride\n                            }\n                        }\n                    }\n                }\n            "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            mutation MessageCreate($params: CreateMessageInfoInput!, $seriesParams: CreateMessageSeriesInfoInput!) {\n                createMessage(params: $params, seriesParams: $seriesParams) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        "): (typeof documents)["\n            mutation MessageCreate($params: CreateMessageInfoInput!, $seriesParams: CreateMessageSeriesInfoInput!) {\n                createMessage(params: $params, seriesParams: $seriesParams) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            mutation MessageCreateOnSeries($params: CreateMessageInfoInput!, $seriesId: UUID!) {\n                createMessageOnSeries(params: $params, seriesId: $seriesId) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        "): (typeof documents)["\n            mutation MessageCreateOnSeries($params: CreateMessageInfoInput!, $seriesId: UUID!) {\n                createMessageOnSeries(params: $params, seriesId: $seriesId) {\n                    id\n                    series {\n                        id\n                    }\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            query MessageCreateUserGroups {\n                userGroups {\n                    id\n                    name\n                }\n            }\n        "): (typeof documents)["\n            query MessageCreateUserGroups {\n                userGroups {\n                    id\n                    name\n                }\n            }\n        "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;