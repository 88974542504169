import { Chip, Divider, Group, Stack, Text, Textarea, TextInput, Title } from "@mantine/core";
import { RichTextContent } from "shared/components/form/RichTextContent";
import { MessageDetailReviewQuery } from "src/gql/graphql";
import { match, P } from "ts-pattern";

type MessageReviewProps = {
    message: MessageDetailReviewQuery["message"];
};

export const MessageReview = ({ message }: MessageReviewProps) => {
    return (
        <Stack>
            {message.series.title && <TextInput label="Series Title" value={message.series.title} disabled />}
            {message.series.description && <Textarea label="Series Description" value={message.series.description} disabled />}
            <TextInput label="Subject" value={message.subject} disabled />
            {match({ smsBodyOverride: message.smsBodyOverride })
                .with({ smsBodyOverride: P.not(P.nullish) }, ({ smsBodyOverride }) => <RichTextContent content={smsBodyOverride} />)
                .otherwise(() => (
                    <></>
                ))}

            {match({ emailBodyOverride: message.emailBodyOverride })
                .with({ emailBodyOverride: P.not(P.nullish) }, ({ emailBodyOverride }) => <RichTextContent content={emailBodyOverride} />)
                .otherwise(() => (
                    <></>
                ))}

            {match({ body: message.body })
                .with({ body: P.not(P.nullish) }, ({ body }) => <RichTextContent content={body} />)
                .otherwise(() => (
                    <></>
                ))}

            <TextInput label="Severity" value={message.severity} disabled />
            <Divider />
            <Title order={3}>Audience</Title>
            <Group>
                {message.groupRecipients.map((group) => (
                    <Chip key={group.name} variant="outline" radius="xs" disabled>
                        {group.name} ({group.memberCount})
                    </Chip>
                ))}
            </Group>
            <Text>Total ({message.recipientCount})</Text>
        </Stack>
    );
};
