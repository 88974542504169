import { Container, Text, Title } from "@mantine/core";
import classes from "shared/components/global/NotFound/NotFound.module.css";

export const NotFound = () => {
    return (
        <Container className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>You have found a secret place.</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
                Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.
            </Text>
        </Container>
    );
};
