import { DateTimePicker, DateTimePickerProps } from "@mantine/dates";
import {
    Controller,
    ControllerFieldState,
    ControllerRenderProps,
    FieldPath,
    FieldValues,
    Path,
    PathValue,
    useFormContext,
    UseFormStateReturn,
} from "react-hook-form";

type FormDateTimepickerProps<T extends FieldValues> = {
    name: FieldPath<T>;
} & Omit<DateTimePickerProps, "onChange" | "value">;

export const FormDateTimepicker = <T extends FieldValues>({ name, label, disabled, ...rest }: FormDateTimepickerProps<T>) => {
    const { setValue, control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { value },
                fieldState: { error },
            }: {
                field: ControllerRenderProps;
                fieldState: ControllerFieldState;
                formState: UseFormStateReturn<FieldValues>;
            }) => {
                const dateValue = value ? new Date(value as number) : null;

                return (
                    <DateTimePicker
                        {...rest}
                        label={label}
                        value={dateValue}
                        onChange={(date) => {
                            setValue(name, date as PathValue<T, Path<T>>);
                        }}
                        error={error?.message?.toString()}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};
